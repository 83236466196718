import React from 'react';
import { Preview } from 'views/preview';
import Layout from 'layouts/preview';

const PreviewPage = ({ location }) => {
    return (
        <Layout location={location}>
            <Preview location={location} />
        </Layout>
    );
};

export default PreviewPage;
