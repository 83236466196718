import React from 'react';
import styled from 'styled-components';
import { Navbar } from 'components/navbar';
import { IS_ANNOUNCEMENT_BANNER_SHOWN } from 'components/navbar/constants';
import { CONSTANTS } from 'constants/styles/constants';
import { PageFooter } from 'modules/page-footer';
import { TranslationsProvider } from 'modules/translations-provider';
import { node, object } from 'prop-types';
import { GlobalStyles } from 'styles/global-styles';

const SCoreContainer = styled.div`
    background-color: ${({ isBlackTheme }) =>
        isBlackTheme ? 'var(--secondary-color)' : 'initial'};
`;

const SMain = styled.main`
    padding-top: ${({ navbar }) =>
        navbar && IS_ANNOUNCEMENT_BANNER_SHOWN ? '4rem' : '0'};
    ${CONSTANTS.MEDIA.max1024`
        padding-top: 0;
    `}
`;

const Layout = ({ children, location }) => {
    const url = location.pathname;

    return (
        <>
            <GlobalStyles />
            <TranslationsProvider>
                <SCoreContainer id="core-container" isBlackTheme={false}>
                    <Navbar url={url} />
                    <SMain>{children}</SMain>
                    <PageFooter url={url} />
                </SCoreContainer>
            </TranslationsProvider>
        </>
    );
};

Layout.propTypes = {
    children: node,
    location: object.isRequired,
};

Layout.defaultProps = {
    children: <div />,
};

export default Layout;
